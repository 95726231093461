﻿import { global } from "./Common/saop-common";
import { SaopUtils } from "./Core/saop-utils";
import { SaopTogglePanel } from "./Components/saop-toggle-panel";
import { SaopInputMask } from "./Components/saop-inputmask";
import { SaopCheckboxSwitch } from "./Components/saop-checkbox-switch";
import { SaopMultiselect  } from "./Components/saop-multiselect";
import { SaopViewSpinner } from './Components/saop-view-spinner';
import { SaopRightSidebar } from './Components/saop-right-sidebar';
import { MenuSidebar } from "./Components/saop-menu-sidebar";
import { SaopMessageDlg } from "./Components/saop-message-dlg";
import { SaopPopupDialog } from './Components/saop-popup-dialog'
import { SaopFloatingLabel } from "./Components/saop-floating-label";
import { SaopServicesCore } from './Core/saop-services-core';
import { SaopTranslationService } from './Core/saop-translate';
import { SaopWebGrid } from './Components/saop-grid';
import { SaopViewForm } from './Core/saop-view-form';
import { SaopTreeview } from './Components/saop-treeview';
import { SaopSelectizeSelect } from './Components/saop-selectize-select';

export function echoTest(msg:string): void {
    console.log("echo: "+msg);
}

export function checkCompatibility(): boolean {
    global.ConsoleLogDegug("checkCompatibility");
    let utils = new SaopUtils();
    return utils.checkCompatibility();
}

export function initClientLogModeValue() {
    global.debugMode = global.GetClientLogModeStorageValue();
}

export function initSiteBaseUrlValue() {
    global.siteBaseUrl = global.getSiteBaseUrl()
}

export function initializeSiteJS() {
    global.ConsoleLogDegug("initializeSiteJS");
    new MenuSidebar().init();
    new SaopTogglePanel().init();

    document.addEventListener('keydown', function (e) {
        if (e.ctrlKey && e.altKey && e.keyCode == 79) {
            // CTRL + ALT + O
            global.SetClientLogModeStorageValue();
            global.debugMode = global.GetClientLogModeStorageValue();
        }
    });

    global.setHelpLink();
}

export function setFirstControlToFocus(formId: string) {
    global.setFirstFormControlToFocus(formId);
}

export {global};



export class SaopTogglePanelEx extends SaopTogglePanel{};

export class SaopInputMaskEx extends SaopInputMask{};

export class SaopCheckboxSwitchEx extends SaopCheckboxSwitch{};

export class SaopMultiselectEx extends SaopMultiselect{};

export class SaopMessageDlgEx extends SaopMessageDlg{};

export class SaopPopupDialogEx extends SaopPopupDialog{};

export class SaopViewSpinnerEX extends SaopViewSpinner { };

export class SaopRightSidebarEx extends SaopRightSidebar { };

export class SaopFloatingLabelEx extends SaopFloatingLabel { };

export class SaopServicesCoreEx extends SaopServicesCore { };

export class SaopTranslationServiceEx extends SaopTranslationService { };

export class SaopWebGridEx extends SaopWebGrid { };

export class SaopViewFormEx extends SaopViewForm { };

export class SaopUtilsEx extends SaopUtils { };

export class SaopTreeviewEx extends SaopTreeview { };

export class SaopSelectizeSelectEx extends SaopSelectizeSelect { };
//
